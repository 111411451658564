import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PageHeaderComponent } from 'src/app/layout/page-header/page-header.component';
import { DisplayAppService } from 'src/app/main/display-app/http/display-app.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../account/http/login.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private loginService: LoginService, private storageService: StorageService, private mappingService: DisplayAppService,
    private router: Router, private pageheader: PageHeaderComponent) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let user = JSON.parse(localStorage.getItem("sessionUserInfo"));
    console.log(user);

    // API Mgmt -> API Approvals
    if (state.url.indexOf('/api-mgmt/approvals') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 || user.roleId.indexOf(1) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Operations Dashboard
    if (state.url.indexOf('/web/ops-support') > -1 || state.url.indexOf('/web/ops-data-management') > -1 || state.url.indexOf('/web/ops-user-management') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(23) > -1 || user.roleId.indexOf(4) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // API Configuration
    else if (state.url.indexOf('/api-configuration') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 || user.roleId.indexOf(1) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // API Mgmt
    else if (state.url.indexOf('/api-mgmt') > -1) {
      if (this.loginService.isAuthenticated()) return true;
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Preference
    else if (state.url == '/web/settings/preference') {
      if (this.loginService.isAuthenticated()) return true;
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Register
    else if (state.url == '/register') {
      if (this.loginService.isAuthenticated()) return true;
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/unauthorized'])
        return false;
      }
    }

    // Admin
    else if (state.url.indexOf('/web/admin') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }
    else if (state.url.indexOf('/web/my-dashboard/supplier') > -1) {

      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 ||  user.roleId.indexOf(17) > -1 || user.roleId.indexOf(16) > -1 || user.roleId.indexOf(18) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }
    else if (state.url.indexOf('/web/supplier-edi/user-management') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 ||  user.roleId.indexOf(17) > -1 ||  user.roleId.indexOf(18) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }
    else if (state.url.indexOf('/web/supplier-edi/request-management') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 ||  user.roleId.indexOf(17) > -1 ||  user.roleId.indexOf(18) > -1 ||  user.roleId.indexOf(16) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }
    else if (state.url.indexOf('/web/supplier-edi/request-management/create') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(16) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }
    // else if (state.url.indexOf('/web/supplier') > -1) {
    //   if (this.loginService.isAuthenticated() == true) {
    //     if (user.roleId.indexOf(4) > -1 ||  user.roleId.indexOf(16) > -1 || user.roleId.indexOf(17) > -1 || user.roleId.indexOf(18) > -1) return true;
    //     else {
    //       this.router.navigate(['/unauthorized']);
    //       return false;
    //     }
    //   }
    //   else {
    //     const redirect = window.location.href;
    //     localStorage.setItem('navigateUrl', redirect);
    //     localStorage.setItem("getauthreturn", 'true');
    //     this.router.navigate(['/login']);
    //     this.pageheader.doLogin();
    //     return false;
    //   }
    // }
       // Platform Admin
       else if (state.url.indexOf('/web/platform-user-management') > -1) {
        if (this.loginService.isAuthenticated() == true) {
          if (user.roleId.indexOf(4) > -1 || user.roleId.indexOf(22) > -1) return true;
          else {
            this.router.navigate(['/unauthorized']);
            return false;
          }
        }
        else {
          const redirect = window.location.href;
          localStorage.setItem('navigateUrl', redirect);
          localStorage.setItem("getauthreturn", 'true');
          this.router.navigate(['/login']);
          this.pageheader.doLogin();
          return false;
        }
      }

    // IS Intake
    else if (state.url.indexOf('/web/is-intake') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        let externalUser = false;
        if (user.tokenUserInfo['nickname'].includes("@")) externalUser = true;
        if (!externalUser) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Utilities
    else if (state.url.indexOf('/web/utilities') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(22) > -1 || user.roleId.indexOf(21) > -1 || user.roleId.indexOf(4) > -1 || 
        user.roleId.indexOf(10) > -1 || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1 || user.roleId.indexOf(23) > -1 || 
        user.roleId.indexOf(24) > -1 || user.roleId.indexOf(25) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Port Check
    else if (state.url.indexOf('/web/port-check') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 || user.roleId.indexOf(10) > -1 || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Home Supplier
    else if (state.url.indexOf('/web/home') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(16) > -1) {
          window.location.href = '/web/supplier';
          return true;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }

    // Home API Portal
    else if (state.url.indexOf('/web/supplier') > -1) {
      debugger
      if (this.loginService.isAuthenticated() == true) {
        if (user?.roleId?.indexOf(16) == -1 && user?.roleId?.indexOf(17) == -1 && user?.roleId?.indexOf(18) == -1 && user?.roleId?.indexOf(4) == -1) {
          window.location.href = '/web/home';
          return true;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    
    // Release Management and Self Service
    else if (state.url.indexOf('/web/release-management') > -1 || state.url.indexOf('/web/self-service') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(10) > -1  || user.roleId.indexOf(4) > -1   || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1 || user.roleId.indexOf(23) > -1 || user.roleId.indexOf(24) > -1 
        || user.roleId.indexOf(25) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Certificate Dashboard
    else if (state.url.indexOf('/web/certificate-dashboard') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(21) > -1 || user.roleId.indexOf(22) > -1  || user.roleId.indexOf(4) > -1) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Exchange Applications
    else if (state.url.indexOf('/web/exchange-applications') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 || (user.roleId.indexOf(10) > -1 || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1)) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Azure ad lookup
    else if (state.url.indexOf('/web/azure-ad-lookup') > -1) {
      if (this.loginService.isAuthenticated() == true) {
        if (user.roleId.indexOf(4) > -1 || (user.roleId.indexOf(10) > -1 || user.role.indexOf('IS Team Lead') > -1 || user.roleId.indexOf(29) > -1 || user.role.indexOf('IS Developer') > -1)) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    // Reports
    else if (state.url.indexOf('/web/reports') > -1) {
      if (this.loginService.isAuthenticated()) {
        let externalUser = false;
        if (user.tokenUserInfo['nickname'].includes("@")) externalUser = true;
        if (!externalUser) return true;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      }
      else {
        const redirect = window.location.href;
        localStorage.setItem('navigateUrl', redirect);
        localStorage.setItem("getauthreturn", 'true');
        this.router.navigate(['/login']);
        this.pageheader.doLogin();
        return false;
      }
    }

    else {
      const redirect = window.location.href;
      localStorage.setItem('navigateUrl', redirect);
      localStorage.setItem("getauthreturn", 'true');
      this.router.navigate(['/unauthorized'])
      return false;
    }
  }
}
