import { Component, OnInit } from '@angular/core';
import { ApiInformationService } from '../api/http/api-information.service';
import { CMSService } from 'src/app/core/cms/cms.service';
import { catchError, forkJoin, map, of, take } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from 'src/app/core/services/data-share.service';
import { LoginService } from 'src/app/account/http/login.service';
import { LoaderService } from 'src/app/core/components/loader/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PartnerApiServiceService } from '../partner-showcases/http/partner-api-service.service';
import { UsecaseApiSrviceService } from '../usecases/http/usecase-api-srvice.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { PageHeaderComponent } from 'src/app/layout/page-header/page-header.component';

@Component({
  selector: 'app-globalsearch',
  templateUrl: './globalsearch.component.html',
  styleUrls: ['./globalsearch.component.scss']
})
export class GlobalsearchComponent implements OnInit {
  isLoggedIn: boolean;
  user;
  globalsearchtxt = "";
  noresults; //= "No Results found";
  usageQuestions = [];
  externalUser = false;
  isforcelogin = false;
  api_list;
  partner_api_list;
  uscase_list;
  tab = "";
  portalQuestions;
  staticUsecaseContent = [];
  searchresultCatalog = [];
  searchresultPartner = [];
  searchresultUsecase = [];
  searchresultFaq = [];
  selectedObj;
  themeValue;
  globalSearchResult = [];
  isApiListEmpty = false;
  constructor(private router: Router,
    private serv: ApiInformationService,
    private cmsService: CMSService,
    private dataShare: DataShareService,
    private loginService: LoginService,
    public loader: LoaderService,
    private sanitizer: DomSanitizer,
    private partnerApiService: PartnerApiServiceService,
    private usecaseApiService: UsecaseApiSrviceService,
    private dataStore: StorageService,
    private route: ActivatedRoute,
    private pageheader: PageHeaderComponent
  ) { }

  ngOnInit(): void {

    this.dataShare.getTheme().subscribe((res: any) => {
      //console.log("gggggggg",res)
      this.themeValue = res;
      this.dataShare.setCssVariables(this.themeValue);
    });

    this.isLoggedIn = this.loginService.isAuthenticated();
    console.log("this.isLoggedIn", this.isLoggedIn)
    console.log("global search");
    this.dataShare.doGlobalSearchTrigger();
    this.dataShare.getGlobalSearchTrigger().pipe(
      // take(1),
      map(res => {
        console.log("con1", res)
        if (res == 'true') {
          this.route.queryParams.subscribe(params => {
            this.tab = params['tab'];
            // console.log("this.tab",this.tab)
          });
          this.searchresultCatalog = [];
          this.searchresultFaq = [];
          this.searchresultPartner = [];
          this.searchresultUsecase = [];
          this.globalsearchtxt = "";
          this.globalsearchtxt = localStorage.getItem("globalsearchtxt");
          console.log("y.....n..", this.searchresultFaq);
          if (this.globalsearchtxt && this.globalsearchtxt != "") {
            this.getData();
          }
          else {
            this.globalSearchResult = [];
            this.noresults = "Please enter the keyword to search.";
          }

        } else {
          this.globalSearchResult = [];
          this.noresults = "Please enter the keyword to search.";
        }
      }),
      catchError(err => {
        console.log("con2", err);
        return of(err)
      })
    ).subscribe();

    // this.searchresultCatalog = [];
    // this.searchresultFaq = [];
    // this.globalsearchtxt = "";
    // this.globalsearchtxt = localStorage.getItem("globalsearchtxt")
    // console.log("y.....n..",this.searchresultFaq);
    // if(this.globalsearchtxt && this.globalsearchtxt != "")
    // {
    //   this.getData();
    // }


  }


  sanitize(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  decodeHTML(value) {
    let res = value.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"').replace(/&apos;/g, "'");
    return res;
  }

  getData() {
    // console.log("lllop.......................1")
    this.searchresultCatalog = [];
    this.searchresultFaq = [];
    this.searchresultPartner = [];
    this.searchresultUsecase = [];
    let req = { loggedIn: this.isLoggedIn }
    if (this.isLoggedIn == true) {

      let rawUser = localStorage.getItem("sessionUserInfo");
      this.user = JSON.parse(rawUser);
      if (this.user.tokenUserInfo['nickname'].includes("@")) {
        this.externalUser = true;
        console.log("external userinside if  ", this.externalUser)
      }

      req["wwid"] = this.user.tokenUserInfo['nickname']; 
      req["action"] = ""; 
      req["external"] = this.externalUser;
      req["email_id"] = JSON.parse(localStorage.getItem("sessionUserInfo")).tokenUserInfo.email;
      req["searchText"] = "";
    }
    // console.log("req,,",req)
    let getApiList
    if (this.isLoggedIn && !this.externalUser) {
      // getApiList = this.serv.getApiList(req);
      let obj = {
        "action": "api_search",
        "searchText": this.globalsearchtxt.toLowerCase()
      }
      getApiList = this.serv.getApiSearhInformationAfterLogin(obj)
    } else {
      let obj = {
        "action": "api_search",
        "searchText": this.globalsearchtxt.toLowerCase()
      }
      getApiList = this.serv.getApiSearhInformationBeforeLogin(obj)
      //getApiList = this.serv.getApiListBeforeLogin("")
    }

    let cmsSupport = this.cmsService.getCMSDataByScreenName('support');

    //partnershowcase
    let obj = { "action": "get_data" }
    let getPartnerList = this.partnerApiService.getPartnerCMS(obj);
    //partnershowcaseend

    //usecase
    let obj2 = { "action": "get_data" }
    let getUsecaseList = this.usecaseApiService.getUsecaseCMS(obj2);
    //usecaseend

    //console.log("this.usageQuestions",this.usageQuestions);
    console.log("iup")
    this.loader.show();
    forkJoin([getApiList, cmsSupport, getPartnerList, getUsecaseList]).subscribe((results: any) => {
      this.loader.hide();
      this.portalQuestions = this.cmsService.getCMSDataByPlaceholderName("support_portalfaqs")
      this.usageQuestions = this.cmsService.getCMSDataByPlaceholderName("support_usagefaqs")
      console.log("results...", results)
      // localStorage.removeItem("globalsearchtxt");
      
      //catelog...
      this.api_list = results[0]; //results[0]["api_list"];
      this.api_list.forEach(element => {
        if (element.display_description == null) element.display_description = "";
        if (element.api_description == null) element.api_description = "";

        if (element.display_summary == null) element.display_summary = "";
        if (element.summary == null) element.summary = "";
      });

      // sort api list based on api name for search precedence
      // testcases -> cmi, product
      this.api_list.sort((a,b) => {
        const searchText = this.globalsearchtxt.toLowerCase();
        const lowerApiName1 = a['api_name'].toLowerCase();
        const lowerAssetId1 = a['asset_id'].toLowerCase();
        const lowerApiName2 = b['api_name'].toLowerCase();
        const lowerAssetId2 = b['asset_id'].toLowerCase();

        // check for text in api name by index -> for parts, parts process > cars part
        if (lowerApiName1.includes(searchText) && lowerApiName2.includes(searchText)) {
          if (lowerApiName1.indexOf(searchText) < lowerApiName2.indexOf(searchText)) return -1
          else if (lowerApiName1.indexOf(searchText) > lowerApiName2.indexOf(searchText)) return 1
        }

        // sort if text is there in api name or not -> for parts, parts process > cmi exp
        else if (lowerApiName1.includes(searchText) && !(lowerApiName2.includes(searchText))) return -1
        else if (!(lowerApiName1.includes(searchText)) && lowerApiName2.includes(searchText)) return 1

        // check for text in asset_id by index
        if (lowerAssetId1.includes(searchText) && lowerAssetId2.includes(searchText)) {
          if (lowerAssetId1.indexOf(searchText) < lowerAssetId2.indexOf(searchText)) return -1
          else if (lowerAssetId1.indexOf(searchText) > lowerAssetId2.indexOf(searchText)) return 1
        }

        // sort by asset_id, same as api name
        else if (lowerAssetId1.includes(searchText) && !(lowerAssetId2.includes(searchText))) return -1
        else if (!(lowerAssetId1.includes(searchText)) && lowerAssetId2.includes(searchText)) return 1

        // next sort based on summary and description same as api name
        else {
          let availableSummaryOfa = a['display_description'].length > 0 ? a['display_description'] : a['api_description'];
          let availableSummaryOfb = b['display_description'].length > 0 ? b['display_description'] : b['api_description'];
          
          let availableDescOfa = a['display_summary'].length > 0 ? a['display_summary'] : a['summary'];
          let availableDescOfb = b['display_summary'].length > 0 ? b['display_summary'] : b['summary'];

          if(availableSummaryOfa.toLowerCase().includes(searchText) && !(availableSummaryOfb.toLowerCase().includes(searchText))) return -1
          else if(!(availableSummaryOfa.toLowerCase().includes(searchText)) && availableSummaryOfb.toLowerCase().includes(searchText)) return 1

          else if(availableDescOfa.toLowerCase().includes(searchText) && !(availableDescOfb.toLowerCase().includes(searchText))) return -1
          else if(!(availableDescOfa.toLowerCase().includes(searchText)) && availableDescOfb.toLowerCase().includes(searchText)) return 1
        }
      });

      this.api_list.forEach(element => {
        let currentSummary;
        let currentDescription;
        if (element.display_summary && element.display_summary != "") {
          currentSummary = this.isBase64(element.display_summary) ? this.decodeHTML(atob(element.display_summary)) : element.display_summary;
        } else {
          currentSummary = element.summary ? element.summary : '';
        }
        if (element.display_description && element.display_description != "") {
          currentDescription = this.isBase64(element.display_description) ? this.decodeHTML(atob(element.display_description)) : element.display_description;
        } else {
          currentDescription = element.api_description ? element.api_description : '';
        }

        let routerlink;
        if (element.api_source == "mulesoft") {
          routerlink = '/web/api/api-detail/' + element.asset_id;
        }
        else if (element.api_source == "static" && element['is_published'] == 1) {
          routerlink = '/web/api/api-detail/' + element.asset_id + "/1";
        }
        else if (element.api_source == "awsExternal") {
          routerlink = '/web/api/api-detail/' + element.asset_id;
        }

        this.searchresultCatalog.push({
          'title': element.api_name,
          'description': (currentDescription.length > 300) ? currentDescription.substring(0, 300) + "..." : currentDescription,
          'routerlink': routerlink ? routerlink : 'web/api/api-list',
          'query': { from: 'globalsearch' },
          'api_source': element.api_source,
          'asset_id': element.asset_id,
          'groupId': element.groupId,
          'is_published': element.is_published
        })
      });
      //end catelog..
      //partnersowcase
      this.partner_api_list = results[2]["partner_showcase_data"];

      this.partner_api_list.forEach(element => {

        if (element.heading.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) ||
          element.description.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) ||
          element.comments.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) ||
          element.checkout_pName.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) ||
          element.tags.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase())
        ) {
          let routerlink = '/web/partner-showcases/';

          this.searchresultPartner.push({
            'title': element.heading,
            'description': (element.description.length > 300) ? element.description.substring(0, 300) + "..." : element.description,
            'routerlink': routerlink,
            'query': { _id: 'partner' + element.id },
            'section': 'partner_showcases'
          });
        }

      });
      //end partnershowcase
      //usease
      this.uscase_list = results[3]["api_usecase_data"];
      this.staticUsecaseContent = results[3]["static_content"];
      this.uscase_list.forEach(element => {

        if (element.usecase_title.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) ||
          element.description.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) ||
          element.api_name.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) ||
          (JSON.stringify(element.tags)).includes(this.globalsearchtxt.toLowerCase()) ||
          element.asset_id.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase())
        ) {
          let routerlink = '/web/api-usecases/details';

          this.searchresultUsecase.push({
            'title': element.usecase_title,
            'data': element,
            'description': (element.description.length > 300) ? element.description.substring(0, 300) + "..." : element.description,
            'routerlink': routerlink,
            'query': { _id: 'usecase' + element.id },
            'section': 'api_usecase'
          });
        }

      });
      //end usecase

      this.searchresultFaq = []
      if (this.portalQuestions) {
        console.log(" this.portalQuestions", this.portalQuestions)
        this.portalQuestions.forEach(element => {
          if (element.heading.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) || element.body.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase())) {
            this.searchresultFaq.push({
              'title': element.heading,
              'description': (element.body.length > 300) ? element.body.substring(0, 300) + "..." : element.body,
              'routerlink': 'web/support/faq',
              'query': { _id: 'faq' + element.id },
              'section': 'faq',
            })
          }
        })
      }

      if (this.usageQuestions) {
        console.log(" this.usageQuestions", this.usageQuestions)
        this.usageQuestions.forEach(element => {
          if (element.heading.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase()) || element.body.toLocaleLowerCase().includes(this.globalsearchtxt.toLowerCase())) {
            this.searchresultFaq.push({
              'title': element.heading,
              'description': (element.body.length > 300) ? element.body.substring(0, 300) + "..." : element.body,
              'routerlink': 'web/support/faq',
              'query': { _id: 'faq' + element.id },
              'section': 'faq',
            })
          }
        })
      }

      //console.log("hi!!!!!!!!!!!!!!!!!!");
      this.globalSearchResult = []
      this.selectedObj = null;
      console.log("faq---", this.searchresultFaq)
      if (this.searchresultCatalog.length > 0) {
        this.globalSearchResult.push({ "category": "API Catalog", "data": this.searchresultCatalog });
      }
      if (this.searchresultUsecase.length > 0 && this.tab != 'api') {
        this.globalSearchResult.push({ "category": "API Usecases", "data": this.searchresultUsecase });
      }
      if (this.searchresultPartner.length > 0 && this.tab != 'api') {
        this.globalSearchResult.push({ "category": "Partner Showcases", "data": this.searchresultPartner });
      }
      if (this.searchresultFaq.length > 0 && this.tab != 'api') {
        this.globalSearchResult.push({ "category": "FAQ's", "data": this.searchresultFaq });
      }

      if (localStorage.getItem("selectedObj")) {
        this.selectedObj = JSON.parse(localStorage.getItem("selectedObj"));
      } else {
        this.selectedObj = this.globalSearchResult[0]
      }

      if (this.selectedObj?.data?.length) {
        this.selectedObj.data.forEach(element => {
          element.description = element.description ? this.decodeHTML(element.description) : element.description;
        });
      }
      
      this.noresults = "No Results found";
      if (this.globalSearchResult.length == 0 && this.isLoggedIn == false) {
        // no result
        this.isforcelogin = true;
        // this.forcelogin
      }
      if (!this.selectedObj && !this.globalSearchResult.length) {

        this.isApiListEmpty = true;
      }

    }, err => {
      this.loader.hide();
      this.globalSearchResult = []
      this.selectedObj = null;
      if (!this.selectedObj) {
        this.isApiListEmpty = true;
      }
      if (this.searchresultCatalog.length > 0) {
        this.globalSearchResult.push({ "category": "API Catalog", "data": this.searchresultCatalog });
      }
      if (this.searchresultUsecase.length > 0 && this.tab != 'api') {
        this.globalSearchResult.push({ "category": "API Usecases", "data": this.searchresultUsecase });
      }
      if (this.searchresultPartner.length > 0 && this.tab != 'api') {
        this.globalSearchResult.push({ "category": "Partner Showcases", "data": this.searchresultPartner });
      }
      if (this.searchresultFaq.length > 0 && this.tab != 'api') {
        this.globalSearchResult.push({ "category": "FAQ's", "data": this.searchresultFaq });
      }


      this.selectedObj = this.globalSearchResult[0]
      console.log("error--->", err)
      // todo
      this.noresults = "No Results found";

      if (this.globalSearchResult.length == 0 && this.isLoggedIn == false) {
        // no result
        this.isforcelogin = true;
        // this.forcelogin
      }
    }).add(() => {
      this.loader.hide();
    })

  }

  forcelogin() {
    const redirect = window.location.href;
    this.dataStore.setInStorage('navigateUrl', redirect);
    if (this.globalsearchtxt) {
      this.dataStore.setInStorage('globalsearchtxt', this.globalsearchtxt.toLowerCase());
      this.dataStore.setInStorage('globalsearch', 'true');
    }
    this.pageheader.doLogin();
  }


  selectCat(resObj) {
    this.selectedObj = resObj
    localStorage.setItem("selectedObj", JSON.stringify(this.selectedObj));
  }

  isBase64(base64Data) {
    var str = base64Data;

    try {
      if (str === '' || str.trim() === '') { return false; }

      var base64Rejex = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;
      var isBase64Valid = base64Rejex.test(base64Data); // base64Data is the base64 string
      if (isBase64Valid) {
        return btoa(atob(str)) == str;
      } else {
        return false;
      }

    } catch (err) {
      return false;
    }
  }

  rdirectResult(section, data) {
    if (section == 'api_usecase') {
      this.dataStore.setObjectInStorage('usecasedetail', data);
      this.dataStore.setObjectInStorage('usecasedetailStaticContent', this.staticUsecaseContent);
      this.router.navigate(['/web/api-usecases/details'], { queryParams: { v: 3 } });
    }
  }

}
