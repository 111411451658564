const HOME = {
  label: 'Home',
  route: '/web/home',
  dropdown: false
};

const IS_INTAKE = {
  label: 'IS Intake',
  route: '/web/is-intake',
  dropdown: false
};

const ABOUT_US = {
  label: 'About Us',
  route: '/web/about-us',
  dropdown: false
};

const APIS = {
  label: 'APIs',
  dropdown: true,
  children: [
    { label: 'Getting Started', route: '/web/getting-started', dropdown: false },
    { label: 'API Use Cases', route: '/web/api-usecases', dropdown: false },
    { label: 'Partner Showcases', route: '/web/partner-showcases', dropdown: false },
  ]
};

// external user
const DASHBOARD_DEFAULT = {
  label: 'My Dashboard',
  dropdown: true,
  children: [
    { label: 'API Management', route: '/web/api-mgmt/requests', dropdown: false },
  ]
}

// internal consumer
const DASHBOARD_CONSUMER = {
  label: 'My Dashboard',
  dropdown: true,
  children: [
    { label: 'API Management', route: '/web/api-mgmt/requests', dropdown: false },
    // { label: 'Azure AD Lookup', route: '/web/azure-ad-lookup/stos-details', dropdown: false },
    { label: 'Reports', route: '/web/reports', dropdown: false }
  ]
}

// api owner
const DASHBOARD_OWNER = {
  label: 'My Dashboard',
  dropdown: true,
  children: [
    { label: 'API Management', route: '/web/api-mgmt/approvals', dropdown: false },
    // { label: 'Azure AD Lookup', route: '/web/azure-ad-lookup/stos-details', dropdown: false }, // need to confirm
    { label: 'API Configuration', route: '/web/api-configuration', dropdown: false },
    { label: 'Reports', route: '/web/reports', dropdown: false }
  ]
}

// IS Team Lead or IS Developer
const DASHBOARD_IS_TEAM_LEAD_DEVELOPER = {
  label: 'My Dashboard',
  dropdown: true,
  children: [
    { label: 'API Management', route: '/web/api-mgmt/requests', dropdown: false },
    { label: 'Azure AD Lookup', route: '/web/azure-ad-lookup/stos-details', dropdown: false },
    { label: 'Exchange Applications', route: '/web/exchange-applications', dropdown: false },
    { label: 'Reports', route: '/web/reports', dropdown: false }
  ]
}

// (api owner X IS Team Lead) OR api owner X IS Developer
const DASHBOARD_OWNER_IS_TEAM_LEAD_DEVELOPER = {
  label: 'My Dashboard',
  dropdown: true,
  children: [
    { label: 'API Management', route: '/web/api-mgmt/approvals', dropdown: false },
    { label: 'Azure AD Lookup', route: '/web/azure-ad-lookup/stos-details', dropdown: false }, // need to confirm
    { label: 'API Configuration', route: '/web/api-configuration', dropdown: false },
    { label: 'Exchange Applications', route: '/web/exchange-applications', dropdown: false },
    { label: 'Reports', route: '/web/reports', dropdown: false }
  ]
}

const DASHBOARD_ADMIN = {
  label: 'My Dashboard',
  dropdown: true,
  children: [
    { label: 'API Management', route: '/web/api-mgmt/approvals', dropdown: false },
    { label: 'Azure AD Lookup', route: '/web/azure-ad-lookup/stos-details', dropdown: false },
    { label: 'API Configuration', route: '/web/api-configuration', dropdown: false },
    { label: 'Admin', route: '/web/admin', dropdown: false },
    { label: 'Exchange Applications', route: '/web/exchange-applications', dropdown: false },
    { label: 'Reports', route: '/web/reports', dropdown: false }
  ]
}

// const SUPPLIER_ADMIN = {
//   label: 'My Dashboard',
//   dropdown: true,
//   children: [
//     { label: 'Dashboard', route: '/web/my-dashboard', dropdown: false },
//     { label: 'Admin', route: '/web/supplier/user-management', dropdown: false },
//   ]
// }


const SUPPLIER_ADMIN = {
  label: 'Admin',
  route: '/web/supplier-edi/user-management'
};

const SUPPLIER_DASHBOARD = {
  label: 'My Dashboard',
  route: '/web/my-dashboard/supplier'
};
const SUPPLIER_NEW_REQUEST = {
  label: 'Create Request',
  route: '/web/supplier-edi/request-management/create'
};


const SUPPORT = {
  label: 'Support',
  route: '/web/support/faq'
};

export const UTILITIES = { 
  label: 'Utilities', 
  route: '/web/utilities', 
  dropdown: false 
};

export const OPS_TEAM = { 
  label: 'Operations Dashboard', 
  route: '/web/ops-support', 
  dropdown: false 
};

const DIGITAL_HUB = {
  label: 'Digital Hub',
  dropdown: true,
  children: [
    { label: 'IS Intake', route: '/web/is-intake', dropdown: false },
    { label: 'IS Asset Repository', route: '/web/is-asset-repo', dropdown: false },
  ]
};

export const VIRTUAL_ASSISTANT = { 
  label: 'Virtual Assistant', 
  route: '/web/virtual-assistant', 
  dropdown: false 
}

export const NAVIGATION = {
  PUBLIC: [APIS, ABOUT_US, SUPPORT],
  DEFAULT: [APIS, DASHBOARD_DEFAULT, ABOUT_US, SUPPORT], // External User
  CONSUMER: [APIS, DASHBOARD_CONSUMER, DIGITAL_HUB, ABOUT_US, SUPPORT], // Consumer
  APIOWNER: [APIS, DASHBOARD_OWNER, DIGITAL_HUB, ABOUT_US, SUPPORT],
  IS_TEAM_LEAD_DEVELOPER: [APIS, DASHBOARD_IS_TEAM_LEAD_DEVELOPER, DIGITAL_HUB, ABOUT_US, SUPPORT],
  API_OWNER_TEAM_LEAD_DEVELOPER: [APIS, DASHBOARD_OWNER_IS_TEAM_LEAD_DEVELOPER, DIGITAL_HUB, ABOUT_US, SUPPORT],
  ADMIN: [APIS, DASHBOARD_ADMIN, DIGITAL_HUB, ABOUT_US, SUPPORT], // Admin
  SUPPLIER_PORTAL_ADMIN:[SUPPLIER_DASHBOARD,SUPPLIER_ADMIN],
  SUPPLIER_PORTAL:[SUPPLIER_DASHBOARD,SUPPLIER_NEW_REQUEST],
  SUPPLER_HOME:[],
  SUPPLIER_PORTAL_ECOMMERCE:[SUPPLIER_DASHBOARD]
};
